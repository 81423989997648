
































































































































































import Modal from "./Modal.vue";
import { US_STATES } from "@/constants";
import { toSnakeCase } from "@/api/conversion";
import { alertError } from "@/services/error_service";

import ValidatedInput from "@/components/form_inputs/ValidatedInput.vue";
import ValidatedCheckbox from "@/components/form_inputs/ValidatedCheckbox.vue";
import ValidatedSelect from "@/components/form_inputs/ValidatedSelect.vue";

import { ValidationObserver } from "vee-validate";

import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    Modal,
    ValidationObserver,
    ValidatedInput,
    ValidatedCheckbox,
    ValidatedSelect
  }
})
export default class SignUpModal extends Vue {
  @Prop({ default: "center" })
  position!: string;

  $auth!: any;
  errorMessage: string | null = null;

  firstName = "";
  lastName = "";
  city = "";
  state = "";
  phoneNumber = "";
  emailAddress = "";
  optionalInfo = "";

  signUpSuccessful = false;

  get stateOptions(): { text: string; value: string }[] {
    return US_STATES;
  }

  async submit(): Promise<void> {
    // @ts-ignore
    const validationSuccessful = await this.$refs.validator.validate();
    if (!validationSuccessful) {
      return;
    }

    const invitationRequestForm = toSnakeCase({
      addressCity: this.city,
      // addressPrimaryLine: "",
      addressState: this.state,
      // addressZip: "",
      comments: this.optionalInfo,
      email: this.emailAddress,
      firstName: this.firstName,
      lastName: this.lastName,
      // ownershipCounties: "",
      phone: this.phoneNumber
    });

    try {
      this.errorMessage = null;
      await this.$auth.submitInvitationRequest(invitationRequestForm);
      this.signUpSuccessful = true;
    } catch (e) {
      this.signUpSuccessful = false;
      const errorMessage =
        "There was an issue submitting your request.\n" +
        "You can reach us at 1 (877) 926-6997 or owners@runtitle.com for support.";
      alertError("Error", errorMessage);
      this.errorMessage = errorMessage;
      throw e;
    }
  }
}
