import * as $ from "jquery";

import Vue from "vue";

Vue.directive("tooltip", {
  inserted: (el: HTMLElement) => {
    // @ts-ignore
    $(el).tooltip();
  },
  unbind: (el: HTMLElement) => {
    // @ts-ignore
    $(el).tooltip("hide");
  }
});
