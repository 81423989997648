import { checkAuth } from "./middleware/auth";
import { setTitle } from "./middleware/page_title";

import Login from "../views/Login.vue";
import SignUp from "../views/SignUp.vue";
import Dashboard from "../views/Dashboard.vue";
import PageNotFound from "../views/PageNotFound.vue";
import ResetPassword from "../views/ResetPassword.vue";
import ResetPasswordConfirmation from "../views/ResetPasswordConfirmation.vue";
import ResetPasswordConfirmed from "../views/ResetPasswordConfirmed.vue";
import Account from "../views/Account.vue";

import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

export enum Navbar {
  LEFT = "left",
  TOP = "top"
}

const routes = [
  {
    path: "/",
    name: "dashboard",
    component: Dashboard,
    props: {
      demo: false
    },
    meta: {
      auth: true,
      navbarCollapsable: true,
      dashboard: true,
      title: "RunTitle - My Ownership"
    }
  },
  {
    path: "/owner/:identityId",
    name: "dashboard-masquerade",
    component: Dashboard,
    props: route => ({
      identityId: route.params.identityId,
      demo: false
    }),
    meta: {
      auth: true,
      dashboard: true,
      navbarCollapsable: true,
      title: "RunTitle - My Ownership"
    }
  },
  {
    path: "/demo",
    name: "demo-dashboard",
    component: Dashboard,
    props: {
      demo: true
    },
    meta: {
      auth: false,
      dashboard: true,
      navbar: Navbar.LEFT,
      navbarCollapsable: true,
      title: "RunTitle - Demo"
    }
  },
  {
    path: "/account",
    name: "account",
    component: Account,
    meta: {
      auth: true,
      navbarCollapsable: true,
      title: "RunTitle - Account"
    }
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      auth: false,
      redirectIfLoggedIn: true,
      title: "RunTitle - Login"
    }
  },
  {
    path: "/(data|mail|mailer)/:signupCodeProp?",
    redirect: { name: "sign-up" }
  },
  {
    path: "/sign-up/:signupCodeProp?",
    name: "sign-up",
    component: SignUp,
    props: true,
    meta: {
      auth: false,
      redirectIfLoggedIn: true,
      title: "RunTitle - Sign up"
    }
  },
  // Need this route for backwards compatibility with old owner pages app
  {
    path: "/activateEmail",
    name: "sign-up-legacy",
    component: SignUp,
    props: true,
    meta: { auth: false, redirectIfLoggedIn: true }
  },
  {
    path: "*",
    component: PageNotFound,
    meta: {
      auth: false,
      title: "RunTitle - Page Not Found"
    }
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: ResetPassword,
    meta: {
      auth: false,
      title: "RunTitle - Reset Password"
    }
  },
  {
    path: "/reset-password-confirmed",
    name: "reset-password-confirmed",
    component: ResetPasswordConfirmed,
    meta: {
      auth: false,
      title: "RunTitle - Reset Password Confirmed"
    }
  },
  {
    path: "/reset-password-confirm/:uidb36/:token",
    name: "reset-password-confirmation",
    component: ResetPasswordConfirmation,
    props: true,
    meta: {
      auth: false,
      title: "RunTitle - Reset Password"
    }
  }
];

const router = new VueRouter({
  // The default mode for vue-router is "hash" mode:
  // it uses the URL hash to simulate a full URL so that the page won't be reloaded when the URL changes.
  // We use "history" mode to get proper URLS (e.g. "/sign-up" instead of "/#sign-up"):
  // https://router.vuejs.org/guide/essentials/history-mode.html
  mode: "history",
  routes
});

router.beforeEach(checkAuth);
router.beforeEach(setTitle);

export default router;
