

































import ContactInfoBox from "@/components/account/ContactInfoBox.vue";
import PasswordBox from "@/components/account/PasswordBox.vue";
import { AccountActions } from "@/store/modules/account/types";

import { ValidationObserver } from "vee-validate";

import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { ContactInfoBox, PasswordBox, ValidationObserver }
})
export default class AccountForm extends Vue {
  $refs!: {
    validator: InstanceType<typeof ValidationObserver>;
    contactInfoForm: any;
    changePasswordForm: any;
  };
  $auth!: any;

  async validate(): Promise<boolean> {
    return this.$refs.validator.validate();
  }

  async updatePassword(): Promise<void> {
    const formData = this.$refs.changePasswordForm;

    if (!formData.newPassword || !formData.passwordConfirmation) return;

    try {
      await this.$auth.apiClient.changePassword(
        formData.newPassword,
        formData.passwordConfirmation,
        formData.oldPassword
      );
      // TODO Display success message
      this.$refs.changePasswordForm.clearForm();
    } catch (e) {
      if (e.response.data) {
        this.$refs.validator.setErrors({
          oldPassword: [e.response.data.error]
        });
      }
    }
  }

  async updateSettings(): Promise<void> {
    const settingsUpdates = this.$refs.contactInfoForm.formData;

    // TODO Only update if this form is dirty

    await this.$store.dispatch(
      AccountActions.UPDATE_ACCOUNT_SETTINGS,
      settingsUpdates
    );

    // TODO Set form errors on fail
  }

  async submit(): Promise<void> {
    await this.validate();
    await this.updateSettings();
    await this.updatePassword();
    this.$refs.validator.reset();
  }

  cancel() {
    this.$router.push("/");
  }
}
