

























import { ModalPosition } from "@/types/modal";

import TopNavBar from "@/components/navbars/TopNavBar.vue";
import LeftNavBar from "@/components/navbars/LeftNavBar.vue";
import Preloader from "@/components/Preloader.vue";
import ContactModal from "@/components/modals/ContactModal.vue";
import SignUpModal from "@/components/modals/SignUpModal.vue";
import Background from "@/components/Background.vue";

import { AppGetter, AppMutation } from "@/store/modules/app/types";
import { Navbar } from "@/router/index";

import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {
    TopNavBar,
    LeftNavBar,
    Preloader,
    ContactModal,
    SignUpModal,
    Background
  }
})
export default class App extends Vue {
  $refs!: { page: any };

  get isLoading(): boolean {
    return this.$store.getters[AppGetter.IS_LOADING];
  }

  get isAuth(): boolean {
    return this.$route.meta.auth;
  }

  get isSignUpButtonVisible(): boolean {
    return !this.isAuth;
  }

  get isTopNavBarVisible(): boolean {
    if (this.$route.meta.navbar !== undefined) {
      return this.$route.meta.navbar === Navbar.TOP;
    } else {
      return !this.isAuth;
    }
  }

  get isLeftNavBarVisible(): boolean {
    if (this.$route.meta.navbar !== undefined) {
      return this.$route.meta.navbar === Navbar.LEFT;
    } else {
      return this.isAuth;
    }
  }

  get isNavbarCollapsable(): boolean {
    return !!this.$route.meta.navbarCollapsable;
  }

  get contactInfoPosition(): string {
    return this.isTopNavBarVisible ? ModalPosition.RIGHT : ModalPosition.CENTER;
  }

  get ownerPageVersion() {
    const userData = this.$store.state.auth.userData;
    return userData ? userData.owner_page_version : null;
  }

  @Watch("ownerPageVersion", { deep: true })
  userVersionSet(oldValue, newValue) {
    // During the v1 -> v2 rollout, existing users will be redirected
    // to the v1 domain until their version flag is updated.
    const base = process.env.VUE_APP_OP_V1_URL;
    if (base && newValue === "v1") {
      window.location.href = `${base}${window.location.pathname}${window.location.search}`;
    }
  }

  @Watch("$route", { immediate: true })
  onRouteChange() {
    // wait until a component inside <router-view /> is rendered
    this.$nextTick(() => {
      const pageBackgroundColor = this.$refs.page.PAGE_BACKGROUND_COLOR;
      const navbarHamburgerColor = this.$refs.page.NAVBAR_HAMBURGER_COLOR;

      if (pageBackgroundColor !== undefined) {
        this.$store.commit(
          AppMutation.SET_BACKGROUND_COLOR,
          pageBackgroundColor
        );
      } else {
        this.$store.commit(AppMutation.SET_BACKGROUND_COLOR, null);
      }
      if (navbarHamburgerColor !== undefined) {
        this.$store.commit(
          AppMutation.SET_HAMBURGER_COLOR,
          navbarHamburgerColor
        );
      } else {
        this.$store.commit(AppMutation.SET_HAMBURGER_COLOR, null);
      }
    });
  }
}
