

















import { Component, Vue } from "vue-property-decorator";

@Component
export default class ResetPasswordConfirmed extends Vue {}
