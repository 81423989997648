import { Module, VuexModule, Mutation } from "vuex-module-decorators";

const DEFAULT_TITLE = "RunTitle";

type CssColor = string | null;

@Module({ namespaced: true })
export default class AppModule extends VuexModule {
  loading: boolean = false;
  _backgroundColor: CssColor = null;
  _hamburgerColor: CssColor = null;

  get isLoading(): boolean {
    return this.loading;
  }

  get backgroundColor(): CssColor {
    return this._backgroundColor;
  }

  get hamburgerColor(): CssColor {
    return this._hamburgerColor;
  }

  @Mutation
  setLoading(loading: boolean): void {
    this.loading = loading;
  }

  @Mutation
  setBackgroundColor(color: CssColor): void {
    this._backgroundColor = color;
  }

  @Mutation
  setHamburgerColor(color: CssColor): void {
    this._hamburgerColor = color;
  }

  @Mutation
  setTitle(title?: string): void {
    document.title = title || DEFAULT_TITLE;
  }
}
