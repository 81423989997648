import * as _ from "lodash";

import { Geom } from "@/store/modules/holdings/types";

export type Coordinate = [number, number];
export type BoundingBox = [Coordinate, Coordinate];

export function getCentroidFromBounds(bounds: BoundingBox): Coordinate {
  const [[x1, y1], [x2, y2]] = bounds;
  const cx = (x1 + x2) / 2;
  const cy = (y2 + y1) / 2;
  return [cx, cy];
}

export function getBoundsFromCoordinates(
  coordinates: Coordinate[]
): BoundingBox | null {
  let xMin: number | null = null;
  let xMax: number | null = null;
  let yMin: number | null = null;
  let yMax: number | null = null;

  coordinates.forEach(cordPoint => {
    const [x, y] = cordPoint;
    xMin = Math.min(xMin || x, x);
    yMin = Math.min(yMin || y, y);
    xMax = Math.max(xMax || x, x);
    yMax = Math.max(yMax || y, y);
  });

  if (!xMin || !xMax || !yMin || !yMax) {
    return null;
  }

  return [
    [xMin, yMax],
    [xMax, yMin]
  ];
}

export function geomToCoords(geom: Geom): Coordinate[] {
  const coords = geom.coordinates;
  if (geom.type === "MultiPolygon") {
    return _.flatten(_.flatten(coords));
  } else if (geom.type === "Polygon") {
    return _.flatten(coords);
  }
  throw new Error(`Unhandled geom type ${geom.type}`);
}

export function getBoundsFromGeoms(geoms: Geom[]): BoundingBox | null {
  const coords = _.flatten(geoms.map(geomToCoords));
  return getBoundsFromCoordinates(coords);
}

export function getCenterForGeomBounds(geom: Geom): Coordinate | null {
  const bounds = getBoundsFromGeoms([geom]);
  if (!bounds) return null;
  return getCentroidFromBounds(bounds);
}
