
































import { Component, Vue } from "vue-property-decorator";

@Component
export default class AccountMenu extends Vue {
  menuItemActive = 0;

  isActive(index: number): boolean {
    return index === this.menuItemActive;
  }

  selectMenuItem(index: number, hash: string): void {
    this.$emit("scrollTo", hash);
    this.menuItemActive = index;
  }

  async logout(): Promise<void> {
    if (window.confirm("Are you sure you want to logout?")) {
      // @ts-ignore
      await this.$auth.logout(this.$router);
    }
  }
}
