import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import {
  BoundingBox,
  Coordinate,
  getBoundsFromGeoms,
  getCentroidFromBounds
} from "@/geo_utils";

import * as _ from "lodash";

const DEFAULT_CENTER: Coordinate = [-103.47585086262386, 31.74078620120063];
const DEFAULT_FIT_OPTIONS = { padding: 50, duration: 0 };

@Module({ namespaced: true })
export default class MapModule extends VuexModule {
  _mapRef = null;
  _center: Coordinate = DEFAULT_CENTER;
  _bounds: BoundingBox | null = null;

  get center(): Coordinate {
    return this._center;
  }

  get bounds(): BoundingBox | null {
    return this._bounds;
  }

  get map() {
    return this._mapRef;
  }

  @Action({ rawError: true })
  initMap(map): void {
    // @ts-ignore
    this.context.state._mapRef = map;
    if (this._bounds && map && map.actions) {
      map.actions.fitBounds(this._bounds, DEFAULT_FIT_OPTIONS);
    }
  }

  @Action({ rawError: true })
  async initLocation(geoms): Promise<void> {
    const bbox = getBoundsFromGeoms(geoms);
    if (bbox) {
      await this.context.dispatch("zoomToBounds", bbox);
      const center = getCentroidFromBounds(bbox);
      if (center) {
        this.context.commit("setCenter", center);
      }
    }
  }

  @Action({ rawError: true })
  zoomToBounds(boundingBox): void {
    this.context.commit("setBounds", boundingBox);
    const map = _.get(this.map, "map");
    if (map) {
      map.fitBounds(boundingBox, DEFAULT_FIT_OPTIONS);
    }
  }

  @Mutation
  setMap(map): void {
    this._mapRef = map;
  }

  @Mutation
  setCenter(center: Coordinate): void {
    this._center = center;
  }

  @Mutation
  setBounds(bounds: BoundingBox): void {
    this._bounds = bounds;
  }
}
