












































import ValidatedInput from "@/components/form_inputs/ValidatedInput.vue";

import { ValidationObserver } from "vee-validate";

import { Component, Vue, Prop } from "vue-property-decorator";
import { handleAPIError } from "@/services/error_service";

@Component({
  components: {
    ValidationObserver,
    ValidatedInput
  }
})
export default class ResetPassword extends Vue {
  email = "";
  error = "";

  async submit(): Promise<void> {
    // @ts-ignore
    const validationSuccessful = await this.$refs.validator.validate();
    if (!validationSuccessful) {
      return;
    }
    try {
      // @ts-ignore
      await this.$auth.requestPasswordReset(this.email);
      this.$router.push("/reset-password-confirmed");
    } catch (err) {
      this.error = err;
      handleAPIError(err);
    }
  }
}
