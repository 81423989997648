








import AccountMenu from "@/components/account/AccountMenu.vue";
import AccountForm from "@/components/account/AccountForm.vue";

import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { AccountMenu, AccountForm }
})
export default class Account extends Vue {
  PAGE_BACKGROUND_COLOR = "white";
  NAVBAR_HAMBURGER_COLOR = "#767676";

  onMenuItemSelect(hash: string): void {
    window.location.href = hash;
  }
}
