































































import HoldingCard from "@/components/HoldingCard.vue";

import {
  HoldingGroupPopulated,
  HoldingsMutation,
  HoldingsGetter
} from "@/store/modules/holdings/types";
import { RT_ESTIMATE_DESCRIPTION } from "@/constants";

import { Component, Vue, Prop } from "vue-property-decorator";

import * as _ from "lodash";

enum SortedOption {
  OPERATOR = "operator",
  COUNTY = "county",
  VALUE = "value"
}

@Component({ components: { HoldingCard } })
export default class HoldingList extends Vue {
  @Prop({ required: true })
  holdingGroups!: HoldingGroupPopulated[];

  @Prop({ required: false, default: false })
  demo!: boolean;

  sortedBy = SortedOption.OPERATOR;
  restimateTooltip = RT_ESTIMATE_DESCRIPTION;

  sortedOptions = [
    { text: "Operator", value: SortedOption.OPERATOR },
    { text: "Value", value: SortedOption.VALUE },
    { text: "County", value: SortedOption.COUNTY }
  ];

  setActiveCard(holding: HoldingGroupPopulated): void {
    this.$store.commit(HoldingsMutation.SET_ACTIVE_HOLDING_GROUP, holding);
  }

  deactivateCard(holdingGroup: HoldingGroupPopulated): void {
    if (this.isCardActive(holdingGroup)) {
      this.$store.commit(HoldingsMutation.SET_ACTIVE_HOLDING_GROUP, null);
    }
  }

  isCardActive(holdingGroup: HoldingGroupPopulated): boolean {
    return _.isEqual(holdingGroup, this.activeHoldingGroup);
  }

  get activeHoldingGroup(): HoldingGroupPopulated | null {
    return this.$store.getters[HoldingsGetter.GET_ACTIVE_HOLDING_GROUP];
  }

  get userName(): string {
    return this.$store.getters["auth/fullName"];
  }

  get totalValue(): number {
    return _.sumBy(this.holdingGroups, holdingGroup => holdingGroup.value);
  }

  get sortedHoldingGroups(): HoldingGroupPopulated[] {
    return _.sortBy(
      this.holdingGroups,
      holdingGroup => holdingGroup[this.sortedBy]
    );
  }

  goToSignUpPage(): void {
    this.$router.push("/sign-up");
  }
}
