





















































































































































































































































































































































































































































































































































































































































































































































import Modal from "./Modal.vue";

import { Component, Vue } from "vue-property-decorator";

@Component({ components: { Modal } })
export default class TermsOfUseModal extends Vue {}
