// taken from https://dev.to/nkoik/-vuex-pattern-smart-module-registration-15gc
import camelCase from "lodash/camelCase";

// Get all files in this folder and sub-directories ending with `index.ts`.
const requireModule = require.context(".", true, /index\.ts$/);
const modules = {};
requireModule.keys().forEach(fileName => {
  if (fileName === "./index.ts") {
    return;
  }

  // filter fullstops and extension and return a camel-case name for the file
  const moduleName = camelCase(fileName.replace(/index\.ts/g, ""));
  // create a dynamic object with all modules
  modules[moduleName] = {
    // add namespace here
    namespaced: true,
    ...requireModule(fileName).default
    // if you have exported the object with name in the module `js` file
    // e.g., export const name = {};
    // uncomment this line and comment the above
    // ...requireModule(fileName)[moduleName]
  };
});
export default modules;
