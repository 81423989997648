import { Coordinate } from "@/geo_utils";

export type GeoJsonPoint = {
  coordinates: Coordinate;
  type: string;
};

export type GeoJsonMultiPolygon = {
  coordinates: Coordinate[][][];
  type: string;
};

export type GeoJsonPolygon = {
  coordinates: Coordinate[][];
  type: string;
};

export type GeoJsonLineString = {
  coordinates: Coordinate[];
  type: string;
};

export type Geom =
  | GeoJsonMultiPolygon
  | GeoJsonPolygon
  | GeoJsonLineString
  | GeoJsonPoint;

export type Holding = {
  county: string;
  current: boolean;
  endDate: string | null;
  fractionalInterest: number;
  geoIndex: string;
  grossAcreage: number;
  id: number;
  identity: string;
  legalDescription: string | null;
  netAcreage: number;
  startDate: string | null;
  state: string;
  type: string;
  units: string[];
};

export type HoldingGroup = {
  geom: GeoJsonMultiPolygon | null;
  holdings: number[];
  id: number;
};

export type HoldingGroupPopulated = {
  county: string;
  geom: GeoJsonMultiPolygon | null;
  holdings: number[];
  id: number;
  value: number;
  operator: string;
  name: string;
  netAcreage: number;
};

export type Unit = {
  county: string;
  geoIndexes: string[];
  geom: GeoJsonMultiPolygon;
  name: string;
  operator: string;
  size: string;
  state: string;
  stateRegUnit: string;
  type: string;
};

export type StringOrNull = string | null;

export type Valuation = {
  holdingId: number;
  value: number;
};

export type OwnershipInterest = {
  decimalInterest: number;
  stateRegUnit: string;
  year: number;
};

export type Well = {
  api10: string;
  bottomHoleLocation: GeoJsonPoint | null;
  completionDate: StringOrNull;
  finalProductionDate: StringOrNull;
  firstProductionDate: StringOrNull;
  name: string;
  operator: string;
  orientation: string;
  permitApprovedDate: StringOrNull;
  permitCancelledReason: StringOrNull;
  permitExpiryDate: StringOrNull;
  permitFiledDate: StringOrNull;
  permitLastAmendDate: StringOrNull;
  spudDate: StringOrNull;
  state: string;
  stateRegUnit: string | null;
  status: string;
  topHoleLocation: GeoJsonPoint | null;
  type: string;
};

export enum HoldingsAction {
  LOAD_IDENTITY_DATA = "holdings/loadIdentityData",
  LOAD_UNITS = "holdings/loadUnits",
  LOAD_WELLS = "holdings/loadWells"
}

export enum HoldingsGetter {
  GET_ACTIVE_HOLDING_GROUP = "holdings/activeHoldingGroup",
  GET_HOLDING_GROUP_COLOR = "holdings/getGroupColor",
  GET_HOLDING_GROUPS = "holdings/holdingGroupsPopulated",
  GET_OPERATOR_COLOR_BY_NAME = "holdings/operatorColorByName",
  GET_UNITS_BY_HOLDING_GROUP = "holdings/unitsByHoldingGroup",
  GET_INTEREST_BY_UNIT = "holdings/interestByStateRegUnit",
  GET_WELLS_BY_HOLDING_GROUP = "holdings/wellsByHoldingGroup"
}

export enum HoldingsMutation {
  SET_ACTIVE_HOLDING_GROUP = "holdings/setActiveHoldingGroup",
  SET_HOLDINGS = "holdings/setHoldings",
  SET_UNITS = "holdings/setUnits",
  SET_WELLS = "holdings/setWells"
}
