


































































import ValidatedInput from "@/components/form_inputs/ValidatedInput.vue";
import ValidatedSelect from "@/components/form_inputs/ValidatedSelect.vue";

import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { ValidatedInput, ValidatedSelect }
})
export default class PasswordBox extends Vue {
  oldPassword = "";
  newPassword = "";
  passwordConfirmation = "";

  get isPasswordConfirmationRequired(): boolean {
    return this.newPassword.length > 0;
  }

  get isOldPasswordRequired(): boolean {
    return this.newPassword.length > 0 || this.passwordConfirmation.length > 0;
  }

  clearForm(): void {
    this.oldPassword = "";
    this.newPassword = "";
    this.passwordConfirmation = "";
  }
}
