export enum MapMutation {
  SET_MAP = "map/setMap",
  SET_CENTER = "map/setCenter"
}

export enum MapGetter {
  GET_MAP = "map/map",
  GET_CENTER = "map/center"
}

export enum MapAction {
  INIT_MAP = "map/initMap",
  INIT_LOCATION = "map/initLocation",
  ZOOM_TO_BOUNDS = "map/zoomToBounds"
}
