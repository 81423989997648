var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"password"}},[_vm._m(0),_c('div',{staticClass:"panel-body"},[_c('p',{staticClass:"title"},[_vm._v(" Complete this form to reset your password."),_c('br'),_vm._v(" If you've forgotten your current password, use "),_c('router-link',{attrs:{"to":"reset-password"}},[_vm._v("this form")]),_vm._v(" to request a password reset. ")],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 body-item"},[_c('validated-input',{staticClass:"item-input",attrs:{"id":"old-password","type":"password","autocomplete":"current-password","label":"Old Password","rules":{
            min: 8,
            required: _vm.isOldPasswordRequired
          },"vid":"oldPassword"},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 body-item"},[_c('validated-input',{staticClass:"item-input",attrs:{"id":"new-password","type":"password","label":"New Password","autocomplete":"new-password","rules":{
            min: 8,
            notAllNumeric: true
          },"vid":"password"},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})],1),_c('div',{staticClass:"col-md-6 body-item"},[_c('validated-input',{staticClass:"item-input",attrs:{"id":"password-confirmation","type":"password","label":"Confirm Password","autocomplete":"new-password","rules":{
            required: _vm.isPasswordConfirmationRequired,
            min: 8,
            confirmed: 'password',
            notAllNumeric: true
          }},model:{value:(_vm.passwordConfirmation),callback:function ($$v) {_vm.passwordConfirmation=$$v},expression:"passwordConfirmation"}})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"panel-header"},[_c('h5',[_vm._v("Password")])])}]

export { render, staticRenderFns }