import store from "@/store";

import { AppMutation } from "@/store/modules/app/types";

import { Route } from "vue-router";

export const setTitle = (to: Route, from: Route, next: (string?) => void) => {
  if (to.meta) {
    store.commit(AppMutation.SET_TITLE, to.meta.title);
  }
  next();
};
