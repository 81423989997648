









































































import MapLegend from "./MapLegend.vue";
import { MapAction, MapGetter } from "@/store/modules/map/types";
import {
  HoldingGroupPopulated,
  HoldingsGetter,
  Well,
  Geom
} from "@/store/modules/holdings/types";
import { Coordinate } from "@/geo_utils";

import {
  MglGeojsonLayer,
  MglMap,
  MglNavigationControl,
  MglMarker
} from "vue-mapbox";

import { Component, Prop, Vue } from "vue-property-decorator";

const MAPBOX_ACCESS_TOKEN = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN;
const MAPBOX_USER = process.env.VUE_APP_MAPBOX_USERNAME;
const MAPBOX_STYLE_ID = process.env.VUE_APP_MAPBOX_STYLE_ID;

type FillLayer = {
  type: string;
  layout: object;
  paint: {
    "fill-color": string;
    "fill-opacity": number;
  };
};

type LineLayer = {
  type: string;
  layout: object;
  paint: {
    "line-color": string;
    "line-width": number;
  };
};

type MapboxGeoJsonSource = {
  data: {
    type: string;
    geometry: Geom;
  };
};

enum WellOrientation {
  VERTICAL = "V",
  HORIZONTAL = "H"
}

@Component({
  components: {
    MglMap,
    MglGeojsonLayer,
    MglNavigationControl,
    MglMarker,
    MapLegend
  }
})
export default class Mapbox extends Vue {
  @Prop({ required: true })
  holdingGroups!: HoldingGroupPopulated[];

  @Prop({ required: false })
  demo!: boolean;

  accessToken: string = MAPBOX_ACCESS_TOKEN;
  mapStyle = `mapbox://styles/${MAPBOX_USER}/${MAPBOX_STYLE_ID}`;

  get mapCenter(): [number, number] {
    return this.$store.getters[MapGetter.GET_CENTER];
  }

  onMapLoaded(evt) {
    this.$store.dispatch(MapAction.INIT_MAP, this.$refs.map);
  }

  getGroupColor(holdingGroup: HoldingGroupPopulated): string {
    return this.$store.getters[HoldingsGetter.GET_HOLDING_GROUP_COLOR](
      holdingGroup
    );
  }

  getOperatorColor(operatorName: string): string {
    return this.$store.getters[HoldingsGetter.GET_OPERATOR_COLOR_BY_NAME](
      operatorName
    );
  }

  getFillLayer(holdingGroup: HoldingGroupPopulated): FillLayer {
    return {
      type: "fill",
      layout: {},
      paint: {
        "fill-color": this.getOperatorColor(holdingGroup.operator),
        "fill-opacity": 0.2
      }
    };
  }

  getLineLayer(holdingGroup: HoldingGroupPopulated): LineLayer {
    return {
      type: "line",
      layout: {},
      paint: {
        "line-color": this.getGroupColor(holdingGroup),
        "line-width": 2
      }
    };
  }

  getWellLineLayer(): LineLayer {
    return {
      type: "line",
      layout: {},
      paint: {
        "line-color": "black",
        "line-width": 2
      }
    };
  }

  isHorizontal(well: Well): boolean {
    return well.orientation === WellOrientation.HORIZONTAL;
  }

  getHoldingGroupGeoJson(
    holdingGroup: HoldingGroupPopulated
  ): MapboxGeoJsonSource | null {
    if (!holdingGroup.geom) return null;
    return {
      data: {
        type: "Feature",
        geometry: {
          type: holdingGroup.geom.type,
          coordinates: holdingGroup.geom.coordinates
        }
      }
    };
  }

  getWellLineGeoJson(well: Well): MapboxGeoJsonSource {
    let coordinates: Coordinate[] = [];

    if (well.bottomHoleLocation !== null && well.topHoleLocation !== null) {
      coordinates = [
        well.topHoleLocation.coordinates,
        well.bottomHoleLocation.coordinates
      ];
    }
    return {
      data: {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates
        }
      }
    };
  }

  getWellsWithCoordinates(holdingGroup: HoldingGroupPopulated): Well[] {
    return this.$store.getters[HoldingsGetter.GET_WELLS_BY_HOLDING_GROUP](
      holdingGroup
    ).filter(well => well.topHoleLocation || well.bottomHoleLocation);
  }

  getTopCoordinates(well: Well): [number, number] | null {
    if (well.topHoleLocation === null) {
      return null;
    }
    return well.topHoleLocation.coordinates;
  }

  getBottomCoordinates(well: Well): [number, number] | null {
    if (well.bottomHoleLocation === null) {
      return null;
    }
    return well.bottomHoleLocation.coordinates;
  }
}
