import store from "@/store";
import { UserSettings } from "@/store/modules/account/types";
import { toCamelCase, toSnakeCase } from "@/api/conversion";

import { handleAPIError } from "@/services/error_service";

import { apiClientBuilder } from "@runtitle/vue-ums-auth";

export default () => {
  const BASE_PATH = "accounts/";
  const client = apiClientBuilder(process.env.VUE_APP_RT_API_BASE_URL, store);

  return {
    client,

    async getSettings(): Promise<UserSettings> {
      try {
        const resp = await client.get(`${BASE_PATH}settings/`);
        return toCamelCase(resp.data);
      } catch (err) {
        handleAPIError(err);
        throw err;
      }
    },

    async updateSettings(settings: UserSettings): Promise<UserSettings> {
      try {
        const snakeSettings = toSnakeCase(settings);
        const resp = await client.patch(`${BASE_PATH}settings/`, snakeSettings);
        return toCamelCase(resp.data);
      } catch (err) {
        handleAPIError(err);
        throw err;
      }
    }
  };
};
