
















import Modal from "./Modal.vue";

import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ components: { Modal } })
export default class ContactPanel extends Vue {
  @Prop({ default: "right" })
  position!: string;
}
