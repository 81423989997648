































import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class Tracker extends Vue {
  @Prop({ required: true })
  progress!: number;
}
