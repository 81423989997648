import { ValidationProvider, extend } from "vee-validate";
import {
  required,
  confirmed,
  min,
  max,
  email,
  numeric
} from "vee-validate/dist/rules";

import Vue from "vue";

// TODO: use some lib for formatting and validating phone numbers
const PHONE_NUMBER_REGEXP = /^\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
const NOT_ALL_NUMERIC_REGEXP = /[^\d]/;

extend("required", {
  ...required,
  message: "This field is required."
});

extend("confirmed", {
  ...confirmed,
  // TODO: maybe this extension should be applied only for Passwords, not globally
  message: "Passwords must match."
});

extend("min", {
  ...min,
  // {length} is the "Argument Placeholder": https://logaretm.github.io/vee-validate/guide/basics.html#arguments-placeholders
  message: "Must be at least {length} characters."
});

extend("max", {
  ...max,
  message: "Must be not more than {length} characters."
});

extend("email", {
  ...email,
  message: "Please enter a valid email format."
});

extend("numeric", {
  ...numeric,
  message: "Please enter a number."
});

extend("phone", {
  validate: (value: string) => PHONE_NUMBER_REGEXP.test(value),
  message: "Please enter a valid phone format."
});

extend("checkbox", {
  validate: (value: boolean) => value,
  message: "This field is required."
});

extend("notAllNumeric", {
  validate: (value: string) => NOT_ALL_NUMERIC_REGEXP.test(value),
  message: "Must not consist of only numbers"
});

Vue.component("validation-provider", ValidationProvider);
