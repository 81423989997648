import getAccountApiClient from "@/api/account_api";
import { UserSettings } from "./types";

import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

@Module({ namespaced: true })
export default class MapModule extends VuexModule {
  _settings: UserSettings | null = null;

  @Action({ rawError: true })
  async loadSettings(): Promise<void> {
    const accountApiClient = getAccountApiClient();
    const settings = await accountApiClient.getSettings();
    this.context.commit("setSettings", settings);
  }

  @Action({ rawError: true })
  async updateSettings(settingsUpdates: UserSettings): Promise<void> {
    try {
      const accountApiClient = getAccountApiClient();
      const updatedSettings = await accountApiClient.updateSettings(
        settingsUpdates
      );
      this.context.commit("setSettings", updatedSettings);
    } catch {
      throw new Error(`Failed to update settings`);
    }
  }

  get settings(): UserSettings | null {
    return this._settings;
  }

  @Mutation
  setSettings(settings: UserSettings): void {
    this._settings = settings;
  }
}
