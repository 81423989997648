import store from "@/store";
import { toCamelCase } from "@/api/conversion";
import {
  Holding,
  HoldingGroup,
  OwnershipInterest,
  Unit,
  Valuation,
  Well
} from "@/store/modules/holdings/types";

import { handleAPIError } from "@/services/error_service";

import { apiClientBuilder } from "@runtitle/vue-ums-auth";
import { AxiosRequestConfig } from "axios";

export default () => {
  const BASE_PATH = "owners/";
  const client = apiClientBuilder(process.env.VUE_APP_RT_API_BASE_URL, store);

  return {
    client,

    getQueryRequestConfig(
      identityId: string | null,
      overrideParams: any = null
    ): AxiosRequestConfig {
      const params: any = { ...overrideParams };
      if (identityId) params.identity_id = identityId;
      return { params };
    },

    async getIdentityData(
      path: string,
      identityId: string | null,
      params: any = null
    ): Promise<any[]> {
      let hasNext = true;
      let results: any[] = [];
      const config = this.getQueryRequestConfig(identityId, params);

      while (hasNext) {
        try {
          const resp = await client.get(`${BASE_PATH}${path}`, config);
          results = results.concat(resp.data.results);
          const nextUrl = resp.data.next;
          if (!nextUrl) {
            hasNext = false;
          } else {
            const nextParamString = nextUrl.split("?")[1];
            const nextParams = Object.fromEntries(
              new URLSearchParams(nextParamString)
            );
            if (!nextParams) {
              break;
            }
            config.params = {
              ...config.params,
              ...nextParams
            };
          }
        } catch (err) {
          handleAPIError(err);
          throw err;
        }
      }
      return results;
    },

    async getHoldings(identityId: string): Promise<Holding[]> {
      const resp = await this.getIdentityData("holdings/", identityId, {
        limit: 500
      });
      return resp.map(toCamelCase);
    },

    async getHoldingGroups(identityId: string): Promise<HoldingGroup[]> {
      const resp = await this.getIdentityData("holding_groups/", identityId);
      return resp.map(toCamelCase);
    },

    async getOwnershipInterests(
      identityId: string
    ): Promise<OwnershipInterest[]> {
      const resp = await this.getIdentityData(
        "ownership_interest/",
        identityId
      );
      return resp.map(toCamelCase);
    },

    async getUnits(identityId: string): Promise<Unit[]> {
      const resp = await this.getIdentityData("units/", identityId, {
        limit: 500
      });
      return resp.map(toCamelCase);
    },

    async getWells(identityId: string): Promise<Well[]> {
      const resp = await this.getIdentityData("wells/", identityId, {
        limit: 500
      });
      return resp.map(toCamelCase);
    },

    async getValuations(identityId: string): Promise<Valuation[]> {
      const resp = await this.getIdentityData("valuations/", identityId);
      return resp.map(toCamelCase);
    }
  };
};
