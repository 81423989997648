import proxyClient from "@/api/owner_proxy_api";
import demoClient from "@/api/demo";
import {
  Holding,
  HoldingGroup,
  HoldingGroupPopulated,
  OwnershipInterest,
  Unit,
  Valuation,
  Well
} from "./types";
import { MapAction } from "@/store/modules/map/types";
import { ACTIVE_COLOR, FALLBACK_COLOR, OPERATOR_COLORS } from "@/constants";

import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import * as _ from "lodash";

@Module({ namespaced: true })
export default class AppModule extends VuexModule {
  _holdings: Holding[] = [];
  _holdingGroups: HoldingGroup[] = [];
  _ownershipInterests: OwnershipInterest[] = [];
  _units: Unit[] = [];
  _valuations: Valuation[] = [];
  _wells: Well[] = [];
  _identityId: string | null = null;

  _activeHoldingGroup: HoldingGroupPopulated | null = null;
  _operatorColors: Map<string, string> | null = null;

  @Action({ rawError: true })
  async loadIdentityData(params): Promise<void> {
    const { demo, identityId } = params;
    this.context.commit("setIdentityId", identityId);
    await Promise.all([
      this.context.dispatch("loadHoldings", params),
      this.context.dispatch("loadHoldingGroups", params),
      this.context.dispatch("loadUnits", params),
      this.context.dispatch("loadValuations", params),
      this.context.dispatch("loadWells", params),
      this.context.dispatch("loadOwnershipInterests", params)
    ]);
  }

  @Action({ rawError: true })
  async loadHoldings({ demo, identityId }): Promise<void> {
    const holdings = await this.apiClient(demo).getHoldings(identityId);
    this.context.commit("setHoldings", holdings);
  }

  @Action({ rawError: true })
  async loadHoldingGroups({ demo, identityId }): Promise<void> {
    const groups = await this.apiClient(demo).getHoldingGroups(identityId);
    this.context.commit("setHoldingGroups", groups);

    const geoms = groups.map(g => g.geom).filter(g => g);
    await this.context.dispatch(MapAction.INIT_LOCATION, geoms, { root: true });
  }

  @Action({ rawError: true })
  async loadOwnershipInterests({ demo, identityId }): Promise<void> {
    const ownershipInterests = await this.apiClient(demo).getOwnershipInterests(
      identityId
    );
    this.context.commit("setOwnershipInterests", ownershipInterests);
  }

  @Action({ rawError: true })
  async loadUnits({ demo, identityId }): Promise<void> {
    const units = await this.apiClient(demo).getUnits(identityId);
    this.context.commit("setUnits", units);
  }

  @Action({ rawError: true })
  async loadValuations({ demo, identityId }): Promise<void> {
    const valuations = await this.apiClient(demo).getValuations(identityId);
    this.context.commit("setValuations", valuations);
  }

  @Action({ rawError: true })
  async loadWells({ demo, identityId }): Promise<void> {
    const wells = await this.apiClient(demo).getWells(identityId);
    this.context.commit("setWells", wells);
  }

  get apiClient(): (demo: boolean) => any {
    return demo => {
      return demo ? demoClient() : proxyClient();
    };
  }

  get holdings(): Holding[] {
    return this._holdings;
  }

  get ownershipInterests(): OwnershipInterest[] {
    return this._ownershipInterests;
  }

  get valuations(): Valuation[] {
    return this._valuations;
  }

  get holdingGroups(): HoldingGroup[] {
    return this._holdingGroups;
  }

  get holdingById(): (id: number) => Holding {
    return id => {
      return _.find(this._holdings, holding => holding.id === id);
    };
  }

  get populateHoldingGroup(): (group: HoldingGroup) => HoldingGroupPopulated {
    return group => {
      const holdingIds = group.holdings;
      const holdings = holdingIds.map(this.holdingById).filter(h => h);

      const units = _.uniq(_.flatten(holdings.map(this.unitsByHolding)));
      const headlineUnit = units.length ? units[0] : null;
      const headlineHolding = holdings.length ? holdings[0] : null;
      const operatorName = _.get(headlineUnit, "operator", "Non-producing");
      const county =
        _.get(headlineUnit, "county") || _.get(headlineHolding, "county");
      const name =
        _.get(headlineUnit, "name") || _.get(headlineHolding, "geoIndex");

      return {
        ...group,
        county: county,
        value: _.sumBy(holdings, this.valueByHolding),
        operator: operatorName,
        name: name,
        netAcreage: _.sumBy(holdings, holding => holding.netAcreage) || 0
      };
    };
  }

  get holdingGroupsPopulated(): HoldingGroupPopulated[] {
    return this._holdingGroups.map(this.populateHoldingGroup);
  }

  get unitByStateRegUnit(): (stateRegUnit: string) => Unit | null {
    return stateRegUnit => {
      const unit = _.find(
        this._units,
        unit => unit.stateRegUnit === stateRegUnit
      );
      return unit || null;
    };
  }

  get unitsByHolding(): (holding) => Unit[] {
    return holding => {
      return holding.units
        .map(this.unitByStateRegUnit)
        .filter(function(u: Unit | null) {
          return !_.isNull(u);
        });
    };
  }

  get wellsByHoldingGroup(): (holdingGroup: HoldingGroupPopulated) => Well[] {
    return holdingGroup => {
      const units = this.unitsByHoldingGroup(holdingGroup);
      const unitIds = units.map(unit => unit.stateRegUnit);

      return this._wells.filter(well => _.includes(unitIds, well.stateRegUnit));
    };
  }

  get valueByHolding(): (holding) => number {
    return holding => {
      const valuationObj = this.valuations.find(
        v => v.holdingId === holding.id
      );
      return valuationObj ? valuationObj.value : 0;
    };
  }

  get interestByStateRegUnit(): (stateRegUnit: string) => number {
    return stateRegUnit => {
      const interestObj = this.ownershipInterests.find(
        i => i.stateRegUnit === stateRegUnit
      );
      return interestObj ? interestObj.decimalInterest : 0;
    };
  }

  get unitsByHoldingGroup(): (holdingGroup: HoldingGroupPopulated) => Unit[] {
    return holdingGroup => {
      const holdings = holdingGroup.holdings
        .map(this.holdingById)
        .filter(h => h);
      return _.uniq(
        _.flatten(holdings.map(this.unitsByHolding)).filter(u => u)
      );
    };
  }

  get operatorColorByName(): (operatorName: string) => string {
    return operatorName => {
      if (this._operatorColors === null) {
        return FALLBACK_COLOR;
      }

      const color = this._operatorColors.get(operatorName);
      if (color === undefined) {
        return FALLBACK_COLOR;
      }
      return color;
    };
  }

  get getGroupColor(): (holdingGroup: HoldingGroupPopulated) => string {
    return holdingGroup => {
      const isActive = this.activeHoldingGroup === holdingGroup;
      return isActive
        ? ACTIVE_COLOR
        : this.operatorColorByName(holdingGroup.operator);
    };
  }

  get activeHoldingGroup(): HoldingGroupPopulated | null {
    return this._activeHoldingGroup;
  }

  @Mutation
  setIdentityId(identityId: string): void {
    if (this._identityId !== identityId) {
      // If changing identity, clear data
      this._holdings = [];
      this._holdingGroups = [];
      this._ownershipInterests = [];
      this._units = [];
      this._valuations = [];
      this._wells = [];
    }
    this._identityId = identityId;
  }

  @Mutation
  setActiveHoldingGroup(holdingGroup: HoldingGroupPopulated | null): void {
    this._activeHoldingGroup = holdingGroup;
  }

  @Mutation
  setHoldings(holdings: Holding[]): void {
    this._holdings = holdings;
  }

  @Mutation
  setHoldingGroups(groups: HoldingGroup[]): void {
    this._holdingGroups = groups;
  }

  @Mutation
  setOwnershipInterests(ownershipInterests: OwnershipInterest[]): void {
    this._ownershipInterests = ownershipInterests;
  }

  @Mutation
  setValuations(valuations: Valuation[]): void {
    this._valuations = valuations;
  }

  @Mutation
  setUnits(units: Unit[]): void {
    this._units = units;
    this._operatorColors = new Map<string, string>();
    _.uniq(_.map(units, "operator").filter(o => o)).forEach(
      (operator, index) => {
        if (this._operatorColors === null) {
          throw new Error(`operator -> color mapping is empty`);
        }
        const colorIndex = index % OPERATOR_COLORS.length;
        this._operatorColors.set(operator, OPERATOR_COLORS[colorIndex]);
      }
    );
  }

  @Mutation
  setWells(wells: Well[]): void {
    this._wells = wells;
  }
}
