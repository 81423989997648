














import HoldingList from "@/components/HoldingList.vue";
import Mapbox from "@/components/Mapbox.vue";
import DemoBottomBanner from "@/components/DemoBottomBanner.vue";

import {
  HoldingsGetter,
  HoldingsAction,
  HoldingGroupPopulated
} from "@/store/modules/holdings/types";

import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component({ components: { HoldingList, Mapbox, DemoBottomBanner } })
export default class Dashboard extends Vue {
  PAGE_BACKGROUND_COLOR = "#c9c9c9";
  NAVBAR_HAMBURGER_COLOR = "white";

  @Prop({ required: false, default: false })
  demo!: boolean;

  @Prop({ required: false, default: null })
  identityId?: string | null;

  get isDemo(): boolean {
    return !this.$route.meta.auth;
  }

  get holdingGroups(): HoldingGroupPopulated[] {
    return this.$store.getters[HoldingsGetter.GET_HOLDING_GROUPS];
  }

  async loadData(): Promise<void> {
    await this.$store.dispatch(HoldingsAction.LOAD_IDENTITY_DATA, {
      demo: this.demo,
      identityId: this.identityId
    });
  }

  async created(): Promise<void> {
    await this.loadData();
  }

  @Watch("identityId")
  identityChanged() {
    this.loadData();
  }
}
