import store from "@/store";

import { Route, RouteRecord } from "vue-router";

export const checkAuth = (to: Route, from: Route, next: (string?) => void) => {
  // @ts-ignore
  const isAuthenticated = store.state.auth.isAuthenticated;

  const doesRouteRequireAuth = to.matched.some(
    (record: RouteRecord) => record.meta.auth
  );

  const shouldRedirect = to.matched.some(
    (record: RouteRecord) => record.meta.redirectIfLoggedIn
  );

  if (doesRouteRequireAuth && !isAuthenticated) {
    next("/login");
    return;
  }

  if (isAuthenticated && shouldRedirect) {
    next("/");
    return;
  }

  next();
};
