


























import { Component, Vue } from "vue-property-decorator";

@Component
export default class MapLegend extends Vue {
  isDetailsActive = false;

  activateDetails(): void {
    this.isDetailsActive = !this.isDetailsActive;
  }
}
