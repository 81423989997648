


























































import ValidatedInput from "@/components/form_inputs/ValidatedInput.vue";
import { AppMutation } from "@/store/modules/app/types";

import { ValidationObserver } from "vee-validate";

import { Component, Vue } from "vue-property-decorator";

@Component({ components: { ValidatedInput, ValidationObserver } })
export default class Login extends Vue {
  isLoading = false;
  error = "";

  email = "";
  password = "";

  async submit(): Promise<void> {
    // we use "@ts-ignore" here because TypeScript doesn't know about our $refs' types
    // @ts-ignore
    const validationSuccessful = await this.$refs.validator.validate();
    if (!validationSuccessful) {
      return;
    }

    try {
      this.isLoading = true;
      // @ts-ignore
      const isLoginSuccessful = await this.$auth.login({
        login: this.email,
        password: this.password
      });

      if (isLoginSuccessful) {
        this.$router.push("/");
      } else {
        this.error = "Authentication error";
      }
    } catch (err) {
      this.error = err;
    }

    this.isLoading = false;
  }
}
