









import { AppGetter } from "@/store/modules/app/types";

import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class NavbarStyles extends Vue {
  get hamburgerColor(): string | null {
    return this.$store.getters[AppGetter.GET_HAMBURGER_COLOR];
  }
}
