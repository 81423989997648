




















































































































































import ValidatedInput from "@/components/form_inputs/ValidatedInput.vue";
import ValidatedCheckbox from "@/components/form_inputs/ValidatedCheckbox.vue";
import ValidatedSelect from "@/components/form_inputs/ValidatedSelect.vue";
import TermsOfUseModal from "@/components/modals/TermsOfUseModal.vue";

import { ValidationObserver } from "vee-validate";

import { Component, Vue, Prop } from "vue-property-decorator";

enum InterestedOption {
  RESEARCHING = "researchingMyMinerals",
  ESTIMATED_VALUE = "estimatedValueOfMyMinerals",
  SELLING = "sellingMyMinerals",
  OTHER = "other"
}

@Component({
  components: {
    ValidationObserver,
    ValidatedInput,
    ValidatedCheckbox,
    ValidatedSelect,
    TermsOfUseModal
  }
})
export default class SignUpForm extends Vue {
  @Prop({ required: false })
  invitationEmail!: string;

  interestedOptions = [
    { text: "Researching My Minerals", value: InterestedOption.RESEARCHING },
    {
      text: "Estimated Value Of My Minerals",
      value: InterestedOption.ESTIMATED_VALUE
    },
    { text: "Selling My Minerals", value: InterestedOption.SELLING },
    { text: "Other", value: InterestedOption.OTHER }
  ];

  email = "";
  password = "";
  passwordConfirmation = "";
  phone = "";
  interestedIn = InterestedOption.RESEARCHING;
  interestOther = "";
  termsOfUseAccepted = false;

  signupSuccessful = false;
  signupResultAvailable = false;

  isLoading = false;
  signupError = "";

  get shouldDisplayInterestOtherField(): boolean {
    return this.interestedIn === InterestedOption.OTHER;
  }

  get invitationHasEmail(): boolean {
    return this.invitationEmail !== "";
  }

  onTermsOfUseAccept(): void {
    this.termsOfUseAccepted = true;
  }

  onTermsOfUseCancel(): void {
    this.termsOfUseAccepted = false;
  }

  async submit(): Promise<void> {
    // @ts-ignore
    const validationSuccessful = await this.$refs.validator.validate();
    if (!validationSuccessful) {
      return;
    }

    this.isLoading = true;
    this.signupSuccessful = await this.signup();
    this.signupResultAvailable = true;
    this.isLoading = false;
  }

  async signup(): Promise<boolean> {
    try {
      const signupForm = {
        email: this.invitationEmail || this.email,
        interest: this.interestedIn,
        interestOther: this.interestOther,
        password: this.password,
        phone: this.phone,
        terms_of_use_accepted: this.termsOfUseAccepted
      };
      // @ts-ignore
      const signupResponse = await this.$auth.signUp(
        signupForm,
        this.$router,
        "/"
      );
      return true;
    } catch (e) {
      this.signupError = e;
      // The status of invitation acceptance becomes outdated after each call of $auth.signUp()
      // so we should call "acceptInvitation()" again
      this.$emit("signupError");
      return false;
    }
  }
}
