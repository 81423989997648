






































import { ModalPosition } from "@/types/modal.ts";

import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class Modal extends Vue {
  @Prop({ required: true })
  id!: string;

  @Prop({ required: false, default: ModalPosition.CENTER })
  position!: ModalPosition;
}
