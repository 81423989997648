










































import ValidatedInput from "@/components/form_inputs/ValidatedInput.vue";

import { ValidationObserver } from "vee-validate";

import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {
    ValidationObserver,
    ValidatedInput
  }
})
export default class Invitation extends Vue {
  @Prop({ required: true })
  value!: string;

  onInput(value: string): void {
    this.$emit("input", value);
  }

  async submit(): Promise<void> {
    // @ts-ignore
    const validationSuccessful = await this.$refs.validator.validate();
    if (!validationSuccessful) {
      return;
    }

    this.$emit("submit");
  }
}
