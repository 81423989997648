/* eslint-disable no-console */

function trackVueExceptions(atatus, Vue) {
  //@ts-ignore
  const vueInstance = Vue || window.Vue;

  // quit if Vue isn't on the page
  if (!vueInstance || !vueInstance.config) {
    console.warn("no vue instance found");
    return;
  }

  const _oldOnError = vueInstance.config.errorHandler;

  vueInstance.config.errorHandler = function VueErrorHandler(error, vm) {
    //@ts-ignore
    const atatusInstance = atatus || window.atatus;

    // quit if atatus isn't on the page
    if (!atatusInstance || !atatusInstance.config) {
      console.warn("no atatus instance found");
    } else {
      atatusInstance.notify(error, {
        extra: {
          propsData: vm.$options.propsData
        }
      });
    }

    if (typeof _oldOnError === "function") {
      _oldOnError.call(this, error, vm);
    }
    console.error(error);
  };
}

export function configureAtatus(Vue) {
  trackVueExceptions(null, Vue);
}
