










import { AppGetter } from "@/store/modules/app/types";

import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class Background extends Vue {
  get backgroundColor(): string | null {
    return this.$store.getters[AppGetter.GET_BACKGROUND_COLOR];
  }

  // we hide background image when background color is set
  get backgroundImageOverride(): string {
    if (this.backgroundColor !== null) {
      return "background-image: none;";
    }
    return "";
  }
}
