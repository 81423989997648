



















import Invitation from "@/components/sign_up/Invitation.vue";
import SignUpForm from "@/components/sign_up/SignUpForm.vue";

import { AppMutation } from "@/store/modules/app/types";

import { Component, Vue, Prop } from "vue-property-decorator";

@Component({ components: { Invitation, SignUpForm } })
export default class SignUp extends Vue {
  @Prop({ required: false, default: "" })
  signupCodeProp!: string;

  signupCode = this.signupCodeProp;

  invitationEmail = "";
  invitationAccepted = false;
  invitationError = "";

  async acceptInvitation(): Promise<boolean> {
    try {
      // @ts-ignore
      const invitation = await this.$auth.acceptInvitation(this.signupCode);

      if (invitation.email !== undefined) {
        this.invitationEmail = invitation.email;
      }

      return true;
    } catch (e) {
      this.invitationError = e;

      return false;
    }
  }

  async revalidateInvitation(): Promise<void> {
    this.invitationAccepted = await this.acceptInvitation();
  }

  async mounted(): Promise<void> {
    let key: string | (string | null)[] = this.$route.query.key;

    // Query params can return an array of strings if the key is included multiple times in the URL.
    // We only want to use the key if it's a single, non-null string
    if (key && typeof key === "string") {
      this.signupCode = key;
    }

    if (this.signupCode === "") {
      return;
    }
    this.$store.commit(AppMutation.SET_LOADING, true);

    this.invitationAccepted = await this.acceptInvitation();

    this.$store.commit(AppMutation.SET_LOADING, false);
  }
}
