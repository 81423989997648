
















import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class Password extends Vue {
  @Prop({ required: true })
  value!: boolean;

  onInput(event: Event): void {
    if (event.target === null) {
      return;
    }
    const targetElement = event.target as HTMLInputElement;
    this.$emit("input", targetElement.checked);
  }
}
