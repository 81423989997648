






















































































































import { US_STATES } from "@/constants";

import ValidatedInput from "@/components/form_inputs/ValidatedInput.vue";
import ValidatedSelect from "@/components/form_inputs/ValidatedSelect.vue";
import {
  AccountActions,
  AccountGetter,
  UserSettings
} from "@/store/modules/account/types";

import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { ValidatedInput, ValidatedSelect }
})
export default class ContactInfoBox extends Vue {
  formData = {};

  async created(): Promise<void> {
    if (!this.userSettings) {
      try {
        await this.$store.dispatch(AccountActions.LOAD_ACCOUNT_SETTINGS);
      } catch {
        throw new Error("Failed to load user settings");
      }
    }

    this.formData = this.$store.getters[AccountGetter.GET_SETTINGS];
  }

  get userSettings(): UserSettings {
    return this.$store.getters[AccountGetter.GET_SETTINGS];
  }

  get stateOptions(): { text: string; value: string }[] {
    return US_STATES;
  }
}
