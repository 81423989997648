export enum AppMutation {
  SET_LOADING = "app/setLoading",
  SET_BACKGROUND_COLOR = "app/setBackgroundColor",
  SET_HAMBURGER_COLOR = "app/setHamburgerColor",
  SET_TITLE = "app/setTitle"
}

export enum AppGetter {
  IS_LOADING = "app/isLoading",
  GET_BACKGROUND_COLOR = "app/backgroundColor",
  GET_HAMBURGER_COLOR = "app/hamburgerColor"
}
