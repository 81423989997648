






































































































































































import Tracker from "@/components/Tracker.vue";

import { getBoundsFromGeoms } from "@/geo_utils";
import { MapAction } from "@/store/modules/map/types";
import {
  HoldingGroupPopulated,
  Unit,
  HoldingsGetter
} from "@/store/modules/holdings/types";
import { RT_ESTIMATE_DESCRIPTION } from "@/constants";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({ components: { Tracker } })
export default class Card extends Vue {
  @Prop({ required: true })
  holdingGroup!: HoldingGroupPopulated;

  @Prop({ required: false })
  selected!: boolean;

  @Prop({ required: false })
  demo!: boolean;

  restimateTooltip = RT_ESTIMATE_DESCRIPTION;
  isDetailsActive = false;
  showWellAlerts = false;
  showDetailsSection = false;

  toggleDetailedView(): void {
    this.isDetailsActive = !this.isDetailsActive;
  }

  get holdingUnits(): Unit[] {
    return this.$store.getters[HoldingsGetter.GET_UNITS_BY_HOLDING_GROUP](
      this.holdingGroup
    );
  }

  get holdingUnitsCount(): number {
    return this.holdingUnits.length;
  }

  get wellCount(): number {
    return this.$store.getters[HoldingsGetter.GET_WELLS_BY_HOLDING_GROUP](
      this.holdingGroup
    ).length;
  }

  get detailsLabel(): string {
    if (this.isDetailsActive) {
      return "Less Detail";
    }
    return "More Detail";
  }

  get cardColor(): string {
    return this.$store.getters[HoldingsGetter.GET_OPERATOR_COLOR_BY_NAME](
      this.holdingGroup.operator
    );
  }

  get unitInterest(): (stateRegUnit: string) => string {
    return stateRegUnit => {
      return this.$store.getters[HoldingsGetter.GET_INTEREST_BY_UNIT](
        stateRegUnit
      ).toFixed(6);
    };
  }

  get targetModal(): string {
    if (this.demo === true) {
      return "#sign-up-modal";
    }
    return "#contact-modal";
  }

  zoomToHolding(): void {
    const geom = this.holdingGroup.geom;
    if (!geom) {
      return;
    }
    const bbox = getBoundsFromGeoms([geom]);
    this.$store.dispatch(MapAction.ZOOM_TO_BOUNDS, bbox);
  }
}
