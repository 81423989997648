export type UserSettings = {
  address1: string | null;
  address2: string | null;
  city: string | null;
  emailAlternative: string | null;
  firstName: string | null;
  interest: string | null;
  interestOther: string | null;
  lastName: string | null;
  ownerPageVersion: string | null;
  phoneNumber: string | null;
  state: string | null;
  zipCode: string | null;
};

export enum AccountMutation {}

export enum AccountGetter {
  GET_SETTINGS = "account/settings"
}

export enum AccountActions {
  LOAD_ACCOUNT_SETTINGS = "account/loadSettings",
  UPDATE_ACCOUNT_SETTINGS = "account/updateSettings"
}
