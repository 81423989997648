import "mapbox-gl/dist/mapbox-gl.css";

import "./directives/tooltip";
import "./plugins/vee-validate";
import "./plugins/bootstrap";
import "./plugins/notifications";
import "./filters";

import Vue, { CreateElement, VNode } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { configureAtatus } from "@/atatus";

import VueSegment from "@runtitle/vue-segment-plugin";
import VueUmsAuthPlugin from "@runtitle/vue-ums-auth";

Vue.config.productionTip = false;
Vue.use(VueSegment, { router, id: process.env.VUE_APP_SEGMENT_ID });
Vue.use(VueUmsAuthPlugin, {
  store,
  authUrlBase: process.env.VUE_APP_RT_API_BASE_URL
});

Vue.component("v-style", {
  render: function(createElement: CreateElement): VNode {
    return createElement("style", this.$slots.default);
  }
});

const app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

configureAtatus(Vue);
// @ts-ignore
app.$auth.initAuthState();
