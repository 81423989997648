

















































import * as _ from "lodash";
import ValidatedInput from "@/components/form_inputs/ValidatedInput.vue";

import { ValidationObserver } from "vee-validate";

import { Component, Vue, Prop } from "vue-property-decorator";
import { alertError } from "@/services/error_service";

@Component({
  components: {
    ValidationObserver,
    ValidatedInput
  }
})
export default class ResetPasswordConfirmation extends Vue {
  $refs!: {
    validator: InstanceType<typeof ValidationObserver>;
  };

  @Prop({ required: true })
  uidb36!: string;

  @Prop({ required: true })
  token!: string;

  password = "";
  passwordConfirmation = "";

  async submit(): Promise<void> {
    let error: any = null;
    // @ts-ignore
    const validationSuccessful = await this.$refs.validator.validate();
    if (!validationSuccessful) {
      return;
    }

    try {
      // @ts-ignore
      const res = await this.$auth.resetPasswordWithKey(
        this.uidb36,
        this.token,
        this.password,
        this.passwordConfirmation
      );
      if (res) {
        this.$router.push("/");
      } else {
        error = "Unable to reset password";
      }
    } catch (e) {
      error = _.get(e, "response.data.error") || "Unable to reset password";
    }

    if (error) {
      const errors = Array.isArray(error) ? error : [error];
      alertError("Failed resetting password", errors.join(", "));
      this.$refs.validator.setErrors({
        password: errors
      });
    }
  }
}
