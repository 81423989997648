import holdings from "./data/holdings.json";
import holdingGroups from "./data/holding_groups.json";
import ownershipInterests from "./data/ownership_interests.json";
import units from "./data/units.json";
import valuations from "./data/valuations.json";
import wells from "./data/wells.json";

import { toCamelCase } from "@/api/conversion";
import {
  Holding,
  HoldingGroup,
  OwnershipInterest,
  Unit,
  Valuation,
  Well
} from "@/store/modules/holdings/types";

export default () => {
  return {
    async getHoldings(): Promise<Holding[]> {
      return holdings.map(toCamelCase);
    },

    async getHoldingGroups(): Promise<HoldingGroup[]> {
      return holdingGroups.map(toCamelCase);
    },

    async getOwnershipInterests(): Promise<OwnershipInterest[]> {
      return ownershipInterests.map(toCamelCase);
    },

    async getUnits(): Promise<Unit[]> {
      return units.map(toCamelCase);
    },

    async getWells(): Promise<Well[]> {
      return wells.map(toCamelCase);
    },

    async getValuations(): Promise<Valuation[]> {
      return valuations.map(toCamelCase);
    }
  };
};
