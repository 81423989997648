










































































import NavbarStyles from "./NavbarStyles.vue";

import { Component, Vue, Prop } from "vue-property-decorator";

@Component({ components: { NavbarStyles } })
export default class LeftNavBar extends Vue {
  @Prop({ required: false, default: false })
  collapsable!: boolean;

  @Prop({ required: false, default: false })
  showSignUp!: boolean;

  isNavbarDisplayed = true;

  displayNavbar(): void {
    this.isNavbarDisplayed = !this.isNavbarDisplayed;
  }

  async logout(): Promise<void> {
    // @ts-ignore
    await this.$auth.logout(this.$router);
  }

  get userInitials(): string {
    return this.$store.getters["auth/fullName"]
      .split(" ")
      .map(n => n[0])
      .join("")
      .toUpperCase();
  }

  get isDashboard(): boolean {
    return this.$route.meta.dashboard;
  }

  get isAccount(): boolean {
    return this.$route.name === "account";
  }
}
