








import { AppMutation } from "@/store/modules/app/types";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class PageNotFound extends Vue {}
