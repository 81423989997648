export const US_STATES = [
  {
    text: "Alabama",
    value: "AL"
  },
  {
    text: "Alaska",
    value: "AK"
  },
  {
    text: "American Samoa",
    value: "AS"
  },
  {
    text: "Arizona",
    value: "AZ"
  },
  {
    text: "Arkansas",
    value: "AR"
  },
  {
    text: "California",
    value: "CA"
  },
  {
    text: "Colorado",
    value: "CO"
  },
  {
    text: "Connecticut",
    value: "CT"
  },
  {
    text: "Delaware",
    value: "DE"
  },
  {
    text: "District Of Columbia",
    value: "DC"
  },
  {
    text: "Federated States Of Micronesia",
    value: "FM"
  },
  {
    text: "Florida",
    value: "FL"
  },
  {
    text: "Georgia",
    value: "GA"
  },
  {
    text: "Guam",
    value: "GU"
  },
  {
    text: "Hawaii",
    value: "HI"
  },
  {
    text: "Idaho",
    value: "ID"
  },
  {
    text: "Illinois",
    value: "IL"
  },
  {
    text: "Indiana",
    value: "IN"
  },
  {
    text: "Iowa",
    value: "IA"
  },
  {
    text: "Kansas",
    value: "KS"
  },
  {
    text: "Kentucky",
    value: "KY"
  },
  {
    text: "Louisiana",
    value: "LA"
  },
  {
    text: "Maine",
    value: "ME"
  },
  {
    text: "Marshall Islands",
    value: "MH"
  },
  {
    text: "Maryland",
    value: "MD"
  },
  {
    text: "Massachusetts",
    value: "MA"
  },
  {
    text: "Michigan",
    value: "MI"
  },
  {
    text: "Minnesota",
    value: "MN"
  },
  {
    text: "Mississippi",
    value: "MS"
  },
  {
    text: "Missouri",
    value: "MO"
  },
  {
    text: "Montana",
    value: "MT"
  },
  {
    text: "Nebraska",
    value: "NE"
  },
  {
    text: "Nevada",
    value: "NV"
  },
  {
    text: "New Hampshire",
    value: "NH"
  },
  {
    text: "New Jersey",
    value: "NJ"
  },
  {
    text: "New Mexico",
    value: "NM"
  },
  {
    text: "New York",
    value: "NY"
  },
  {
    text: "North Carolina",
    value: "NC"
  },
  {
    text: "North Dakota",
    value: "ND"
  },
  {
    text: "Northern Mariana Islands",
    value: "MP"
  },
  {
    text: "Ohio",
    value: "OH"
  },
  {
    text: "Oklahoma",
    value: "OK"
  },
  {
    text: "Oregon",
    value: "OR"
  },
  {
    text: "Palau",
    value: "PW"
  },
  {
    text: "Pennsylvania",
    value: "PA"
  },
  {
    text: "Puerto Rico",
    value: "PR"
  },
  {
    text: "Rhode Island",
    value: "RI"
  },
  {
    text: "South Carolina",
    value: "SC"
  },
  {
    text: "South Dakota",
    value: "SD"
  },
  {
    text: "Tennessee",
    value: "TN"
  },
  {
    text: "Texas",
    value: "TX"
  },
  {
    text: "Utah",
    value: "UT"
  },
  {
    text: "Vermont",
    value: "VT"
  },
  {
    text: "Virgin Islands",
    value: "VI"
  },
  {
    text: "Virginia",
    value: "VA"
  },
  {
    text: "Washington",
    value: "WA"
  },
  {
    text: "West Virginia",
    value: "WV"
  },
  {
    text: "Wisconsin",
    value: "WI"
  },
  {
    text: "Wyoming",
    value: "WY"
  }
];

export const OPERATOR_COLORS = [
  "#316395",
  "#dc3912",
  "#ff9900",
  "#990099",
  "#0099c6",
  "#dd4477",
  "#66aa00",
  "#109618",
  "#b82e2e",
  "#994499",
  "#22aa99",
  "#aaaa11",
  "#6633cc",
  "#e67300",
  "#8b0707",
  "#651067",
  "#329262",
  "#3366cc",
  "#5574a6",
  "#3b3eac"
];

export const ACTIVE_COLOR = "#f0f0f0";

export const FALLBACK_COLOR = "#329262";

export const RT_ESTIMATE_DESCRIPTION =
  "The RunTitle Estimate is our best estimate of\n" +
  "this asset's market value. It is not an appraisal\n" +
  "and it should be used as a starting point. \n" +
  "We might be missing assets or have incorrect\n" +
  "data that cause our RunTitle Estimate to be incorrect.\n" +
  "Please contact us to resolve any questions or issues.";
