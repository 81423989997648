import Vue from "vue";

interface AxiosError extends Error {
  config?: { url: string; params: string; data: string };
  response?: { data: { message: string }; status: number };
}

const ALERT_DURATION = 10000;

export function handleAPIError(err: AxiosError): void {
  let message = err.message;

  if (err.response === undefined) {
    alertError("Server is not available", message);
    return;
  }

  if (err.response !== undefined && err.response.data.message !== undefined) {
    message = err.response.data.message;
  }

  alertError("API error", message);
}

export function alertError(title: string, message: string): void {
  Vue.notify({
    type: "error",
    duration: ALERT_DURATION,
    title: title,
    text: message
  });
}
